* {
  list-style-type: none
}

.App {
  text-align: center;
}

.Header {
  padding: 0.1rem;
  text-align: center;
  background: #313638;
  color: white;
  font-size: 1rem;
}

.Header a {
  text-decoration: none;
  color:white;
}

.Navbar {
  background-color:   #9DA6AA;
  padding: 4px;
  text-align: center;
  color: white;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.Navbar div {
  margin-left: 1rem;
  margin-right: 1rem;
}

.Navbar a {
  text-decoration: none;
  color:white;
}

.Footer {
  padding: 3px;
  text-align: center;
  color: black;
  font-size: 0.8rem;
  margin-top:100px
}

.ListArticles__container {
  display:flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.ListArticles__item {
  background-color: #faf6f6;
  box-shadow: 0 6px 6px rgba(0,0,0,0.2);
  padding: 0.5rem;
  margin: 0.5rem;
  width:90%;
  flex-wrap: wrap;
  justify-content: center;
  text-align: left;
}

.ListArticles__item:hover {
cursor: pointer;
}

.ListArticles__item a {
  text-decoration: none;
  color:black
}

.smallerFont {
  font-size: smaller;
  margin-left: 1rem;
  margin-right: 1rem;
}

.normalFont {
  margin-left: 1rem;
  margin-right: 1rem;
}

.largerFont {
  font-size:larger;
  margin-left: 1rem;
  margin-right: 1rem;
}



.TopicLabel--cooking {
  font-size: small;
  border:1px #688E26 solid;
  background-color: #688E26;
  color:white;
  font-weight: bold;
  border-radius: 5px;
  padding:2px;
  width: min-content;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 0;
}

.TopicLabel--football {
  font-size: small;
  border:1px #247BA0 solid;
  background-color: #247BA0;
  color:white;
  font-weight: bold;
  border-radius: 5px;
  padding:2px;
  width: min-content;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 0;
}

.TopicLabel--coding {
  font-size: small;
  border:1px #FF4F79 solid;
  background-color: #FF4F79;
  color:white;
  font-weight: bold;
  border-radius: 5px;
  padding:2px;
  width: min-content;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 0;
}



.ListArticleTitle{
  margin: 1rem
}


.TopicMenu {
  background-color: #DDE0E3;
  padding: 4px;
  text-align: center;
  color: rgb(0, 0, 0);
  font-size: 0.85rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.TopicMenu div {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  text-transform: capitalize;
}

.TopicMenu a {
  text-decoration: none;
  color: rgb(0, 0, 0);
}


.Article__container {
  display:flex;
  padding: 5px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.Article__item {
  background-color: #faf6f6;
  box-shadow: 0 6px 6px rgba(0,0,0,0.2);
  border-radius: 1%;
  margin:0.25rem;
  padding: 0.5rem;
  width:100%;
  flex-wrap: wrap;
  justify-content: center;
  text-align: left;
}

#VoteError {
  color:red;
  font-style: italic;
  font-size: 0.8rem;
}

.Comments__container {

  background-color: rgb(255, 255, 255);
  display:flex;
  padding: 5px;
  flex-direction:column-reverse;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.Comments__item {
  border: 0.5px black solid;
  border-radius: 1%;
  padding:10px;
  width:95%;
  flex-wrap: wrap;
  justify-content: center;

  text-align: left;
  font-style: italic;
  flex-direction: column;
  margin-bottom: 0.5rem;
}


.LoginBar {
  padding-right: 1rem;
  text-align: center;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  display: flex;
  justify-content: center;
}

.LoginBar span {
  margin:5px;
  font-style: italic;
  font-size: 0.8rem;
}

.LoginBar button {
  margin-left: 3px;
  font-style: italic;
  font-size: 0.8rem;
  }

fieldset {
  width:75%;
  margin:0 auto
}

.PostAComment_container {
  display:flex;
  padding: 5px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.PostAComment__form {

  margin:0.25rem;
  padding: 0.5rem;
  width:100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  display:flex;
}

.PostAComment__form textarea {
  width: 90vw;
}

.PostAComment__form button {
  flex-wrap: wrap;
  justify-content: center;
  display:flex;
}

.SortMenu__sortby {
  background-color: #cfdae4;
  padding: 4px;
  text-align: center;
  color: rgb(0, 0, 0);
  font-size: 0.85rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.SortMenu__sortby div {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  text-transform: capitalize;
}

.SortMenu__sortby a {
  text-decoration: none;
  color: rgb(0, 0, 0);
}

.SortMenu__order {
  background-color: #9db2c7b3;
  padding: 4px;
  text-align: center;
  color: rgb(0, 0, 0);
  font-size: 0.85rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.SortMenu__order div {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  text-transform: capitalize;
}

.SortMenu__order a {
  text-decoration: none;
  color: rgb(0, 0, 0);
}

.MyDetails {

  display:flex;
  padding: 5px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.MyDetails__information {

  margin:0.25rem;
  padding: 0.5rem;
  width:50%;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;

}

.MyDetails__information img {
width: 50%;
height: 50%;
}

.RemoveButton {
  margin-left: 3rem
}

.SelectedLink {
  font-weight: bold;
  font-style: italic;
}

.NormalLink {
  font-weight:normal;
}




@media (min-width : 600px) {
  .ListArticles__container {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
  }
  
  .ListArticles__item {
    background-color: #faf6f6;
    box-shadow: 0 6px 6px rgba(0,0,0,0.2);
    padding: 0.5rem;
    margin: 0.5rem;
    max-width: 400px;
    min-height: 200px;
    flex-wrap: wrap;
    justify-content: center;
    text-align: left;
    flex-grow:1
  }
}


